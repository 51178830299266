import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import axios from "axios";
import AddUser from "./add-user.component";

const ShowUsers = ({ admin }) => {
  const toast = useRef(null);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [viewCredits, setViewCredits] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const sets = [
    "Beginner",
    "Intermediate",
    "Misc01",
    "Misc02",
    "Misc03",
    "Misc04",
    "Misc05",
    "Queens",
    "Rooks",
    "Bishops",
    "Knights",
    "Pawns",
  ];

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (admin.isNewUserAdded === 1) getAllUsers();
  }, [admin.isNewUserAdded]);

  const getAllUsers = async (e) => {
    try {
      const res = await axios.get("/api/v1/users", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setUsers(res.data.data);
    } catch (err) {}
  };

  const handleSeeder = async () => {
    try {
      const res = await axios.patch("/api/v1/seeders", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    } catch (err) {}
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Users</p>
      <AddUser />
      {/* <Button label='Run Seeder' onClick={handleSeeder} /> */}
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  const yesOrNo = (rowData) => {
    return rowData.isEmailConfirmed === true ? "Yes" : "No";
  };
  const resetCreditsButton = (rowData) => {
    const accept = async () => {
      try {
        const res = await axios.get(`/api/v1/users/reset/${rowData._id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
      } catch (err) {
        console.log("An error occurred ", err);
      }
    };
    const reject = async () => {};

    const confirm1 = () => {
      confirmDialog({
        message: "Are you sure you want to reset all the credits?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };
    return (
      <>
        <ConfirmDialog />
        <Button
          className='p-button-raised p-button-success p-button'
          // onClick={resetCredits}
          label='Reset'
          onClick={confirm1}
        />
      </>
    );
  };

  const resendOrCreditsButton = (rowData) => {
    let resend = false;
    if (
      rowData.isEmailConfirmed === false &&
      rowData.subscriberStatus !== "incomplete"
    )
      resend = true;

    const handleCredits = async (e) => {
      const res = await axios.get(`/api/v1/users/credits/${rowData._id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setViewCredits(res.data.data);
      setShow(true);
    };
    const handleResendInvite = async (e) => {
      const res = await axios.get(
        `/api/v1/users/resend-invite/${rowData._id}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      toast.current.show({
        severity: "success",
        summary: "Invite was sent",
        detail: `An email invite was sent to ${rowData.email}`,
        life: 3000,
      });
    };
    if (resend === true)
      return (
        <Button
          className='p-button-raised p-button-success p-mr-2 p-button'
          onClick={handleResendInvite}
          label='Resend Invite'
        />
      );

    return (
      <Button
        // icon='pi pi-eye'
        className='p-button-raised p-button-warning p-mr-2 p-button'
        onClick={handleCredits}
        label='Credits'
      />
    );
  };

  const expirationDateTemplate = (rowData) => {
    let [year, month, day] = rowData.expirationDate.split("-");
    if (rowData.subscriberStatus === "life") return "Unlimited";
    return `${month}/${day.split("T")[0]}/${year}`;
  };
  const daysLeftTemplate = (rowData) => {
    let daysLeft =
      Math.floor(
        (new Date(rowData.expirationDate) - new Date()) / (1000 * 60 * 60 * 24)
      ) + 1;
    if (daysLeft < 0) daysLeft = 0;
    if (rowData.subscriberStatus === "life") return "Unlimited";
    return daysLeft;
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable
            value={users}
            dataKey='_id'
            paginator={users.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            header={header}
            globalFilter={globalFilter}
          >
            <Column
              field='email'
              header='Email'
              sortable={true}
              style={{ width: "25%" }}
            />
            <Column
              header='Expiration Date'
              sortable={true}
              body={expirationDateTemplate}
              style={{ width: "15%" }}
            />
            <Column
              header='Days Left'
              sortable={true}
              body={daysLeftTemplate}
              style={{ width: "15%" }}
            />
            <Column
              field='subscriberStatus'
              header='Status'
              sortable={true}
              style={{ width: "10%" }}
            />
            <Column
              field='isEmailConfirmed'
              header='Confirmed'
              body={yesOrNo}
              sortable={true}
              style={{ width: "12%" }}
            />
            <Column
              field='role'
              header='Role'
              sortable={true}
              style={{ width: "12%" }}
            />
            <Column body={resetCreditsButton} style={{ width: "11%" }} />
            <Column body={resendOrCreditsButton} style={{ width: "11%" }} />
          </DataTable>
          <Dialog
            header={"Viewing Credits"}
            visible={show}
            style={{ maxWidth: "1000px" }}
            onHide={() => {
              setViewCredits(null);
              setShow(false);
            }}
            baseZIndex={1000}
          >
            {viewCredits !== null && (
              <Fragment>
                <div className='p-grid'>
                  <h6 className='p-col-1'>Set</h6>
                  <h6 className='p-col-1 p-text-right'>Completed</h6>
                  <h6 className='p-col-10 p-text-center'>
                    1 is completed, 0 is not
                  </h6>
                  {sets.map((set) => (
                    <Fragment key={set}>
                      <h6 className='p-col-1 k-my-0'>{set}</h6>
                      <h6 className='p-col-1 p-text-right k-my-0'>
                        {(viewCredits[set].match(/1/g) || []).length}
                      </h6>
                      <h6 className='p-col-10 p-text-left k-my-0'>
                        {viewCredits[set]}
                      </h6>
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            )}
          </Dialog>
        </Card>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, null)(ShowUsers);
